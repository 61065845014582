<template>
    <div class="third">
        <div class="page-1" >

            <div class="w_1200">
                <!-- <img class="img-2" :style="imgGif" src="@/assets/img/dissDao/3/2..gif" alt=""> -->
            </div>
            <router-link to="/fourth" class="go-next" :style="goNextStyle">
                <img :style="enterStyle" src="@/assets/img/dissDao/3/img_01.png" alt="">
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                enterStyle: {},
                imgGifStyle: {},
                goNextStyle: {},
            }
        },
        methods: {
            getValue(val, type) {
                const body_h = document.getElementsByTagName('body')[0].clientHeight;
                return type ? body_h * (val/1177) : body_h * (val/1177) + 'px'
            },
            init() {
                this.imgGifStyle = {
                    height: this.getValue(900),
                    width: this.getValue(906),
                }
                this.enterStyle = {
                    height: this.getValue(67)
                }
                this.goNextStyle = {
                    top: this.getValue(105),
                    right: this.getValue(42)
                }
            }
        },
        mounted () {
            this.init()
            window.onresize=(()=>{
                this.init()
            })
        },
    }
</script>

<style lang="less" scoped>
    .third{
        width: 100%;
        min-width: 1200px;
		min-height: 100vh;
        position: relative;

        background-image: url('./../../assets/img/dissDao/3/3..png'), url('./../../assets/img/dissDao/3/GIF.gif'), url('./../../assets/img/dissDao/3/1.png');
        background-position: center top, 85.2% 30px, center top;
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-size: cover, 250px 531px, cover;

        .page-1{
            width: 100%;
            min-height: 100vh;
            .w_1200{
                width: 1200px;
                margin: 0 auto;
                min-height: 100vh;
                position: relative;
                background: url('./../../assets/img/dissDao/3/5..gif') no-repeat center top;
                background-size: cover;
                // overflow: hidden;
            }
            .go-next{
                position: absolute;
                right: 42px;
                top: 105px;
            }
            .img-gif{
                width: 1200px;
                // height: 800px;
                margin: 0 auto;
                background: url('./../../assets/img/dissDao/3/2..gif') no-repeat right top;
                // background-size: 80%;
                // position: absolute;
                z-index: 3;
            }
        }
    }
    
    @media (max-width: 1500px) {
        .third{
            background-position: center top, 95.2% 30px, center top;
        }
    }
</style>